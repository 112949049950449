/* 封装axios用于发送请求 */
import axios from 'axios'
import router from '@/router'
import { Toast } from 'vant'
import { setTimeout } from 'core-js'

// 并且设置了新对象的基地址
// const baseUrl = 'https://www.robotxiaoi.com' // 线上葡萄牙语

// const baseUrl = 'https://www.openai-coin.com' // 线上英语

// const baseUrl = 'https://argocoin.vip' // 主域名

const baseUrl = 'https://ampgpt.io'

const request = axios.create({
  // baseURL: 'https://www.x.orde.live',
  // 设置超时时间为5秒
  // 意思是说如果发一个请求5秒还没反应就直接报连接超时
  timeout: 50000,
  header: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
	// 'lang':localStorage.getItem('newlang')||'es'
  }
})
request.defaults.baseURL = baseUrl

// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    // JS操作cookies方法!
    // 写cookies
    // function setCookie (name, value) {
    //   const Days = 30
    //   const exp = new Date()
    //   exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)
    //   document.cookie = name + '=' + escape(value) + ';expires=' + exp.toGMTString()
    // }
    // setCookie('lang', localStorage.getItem('lang') || 'en-us')
    // setCookie('think_var', localStorage.getItem('tink_var') || 'en-us')
    // 在发送请求之前做些什么
    // 在发送请求之携带token
    if (localStorage.getItem('token')) {
      // config.headers.Authorization = `Bearer ${getToken()}`
      config.headers.token = `${localStorage.getItem('token')}`
    }
	
	if(!localStorage.getItem('lang')) {
		localStorage.setItem('lang','Español')
		localStorage.setItem('newlang','es')
	}

    config.headers['Accept-Language'] = localStorage.getItem('newlang')||'es'
    // config.headers.Cookie = 'en'

    // config.headers.lang = localStorage.getItem('lang') || 'en-us'
    // config.headers.think_var = localStorage.getItem('tink_var') || 'pt-br'
    // config.data = { lang: localStorage.getItem('lang') || 'en-us', ...config.data, think_var: localStorage.getItem('tink_var') || 'en-us' }

    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    // console.log('111', response)
    // 对响应数据做点什么,取值时少写一个data
    return response.data
  },
  function (error) {
    // 对响应错误做点什么
    console.log(error)
    // console.log('555', error)
    if (error.response.data.code === 401) {
      let res = ''
      if (localStorage.getItem('tink_var') === 'en-us') {
        res = 'Login information has expired, please log in again'
      } else {
        res = 'Login information has expired, please log in again'
      }
      Toast({
        message: res,
        className: 'noticeWidth' // 所加样式名
      })
      localStorage.removeItem('token')
      sessionStorage.removeItem('show')
      localStorage.removeItem('is_email');
      sessionStorage.removeItem('gonggaoshow')
      setTimeout(() => {
        router.push('/login')
      }, 1000)
    }
    if (error.response.status === 403) {
      // console.log(error.response.data)
      router.push({
        path: '/error',
        query: {
          info: error.response.data
        }
      })
    }
    return Promise.reject(error)
  }
)
// 'Login information invalid, please log in again'
// 暴露出去
export default request
