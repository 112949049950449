<template>
  <!-- 提现页面 -->
  <div class="Withdraw">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text='Pull down to refresh' loosing-text="Let it go and update" loading-text='Loading...' pull-distance='100'>
      <div style="min-height: 100vh;">
    <div class="Withdraw-title">
      <van-icon name="arrow-left" size="2rem" color="#000" @click="$router.back()" />
      <div class="title">{{$t('Withdraw.title')}}</div>
      <!--  <div class="img" @click="toWithdrawalsrecord">
          <img src="../assets/record.png" alt="" />
        </div> -->
      <div class="img" @click="toWithdrawalsrecord">
        <img src="../assets/czjl.png" alt="" />
      </div>
    </div>
    <div class="Amount">
      <div class="left">{{$t('Withdraw.Amount')}}</div>
      <div class="right">
        <div class="Available">{{$t('Withdraw.Available')}}</div>
        <div>{{ available }} USDT</div>
      </div>
    </div>
    <div class="money">
      <!-- <input type="text" v-model="money" oninput="this.value = this.value.replace(/[^0-9]/g, '');" @input="numValid(money)"/> -->
      <input type="number" v-model="money" @input="numValid(money)"/>
      <div class=" all" @click="allmoney">{{$t('Withdraw.All')}}
    </div>
  </div>
  <div class="address" v-if="type===1">{{$t('addwith.address')}}</div>
  <div class="address" v-if="type===2">{{$t('addwith.addresser')}}</div>
  <div v-if="type===2" @click="bankaddress" class="addres">{{ Withdrawobj.bank_account }}</div>
  <div v-else-if="type===1" @click="toaddress" class="addres">{{ Withdrawobj.wallet_address }}</div>
  <div>
    <div style="color: #333; margin: 1.5rem 0rem; font-size: 1.6rem">{{$t('addwith.addresssan')}}</div>
    <div class="type">
      <div class="box" v-for="(item,index) in Withdrawobj.channel" :key="index" :class="{boxs:type===item.type}" @click="boxtype(item.type)">{{item.name}}</div> <!-- {{$t('addwith.addresssi')}}-->
      <!-- <div class="box" :class="{boxs:type===1}" @click="boxtype(1)" style="margin-left: 2rem;">USDT</div> -->
    </div>
  </div>
  <div v-if="type===2" class="info">
    <div>{{$t('Withdraw.min')}}:{{ Withdrawobj.bank_min }} USDT</div>
    <div>{{$t('Withdraw.max')}}:{{ Withdrawobj.bank_max }} USDT</div>
  </div>
  <div v-if="type===1" class="info">
    <div>{{$t('Withdraw.min')}}:{{ Withdrawobj.min }} USDT</div>
    <div>{{$t('Withdraw.max')}}:{{ Withdrawobj.max}} USDT</div>
  </div>
  <div v-if="type===1" class="fee">{{$t('Withdraw.fee')}}:{{ Withdrawobj.fee }}</div>
  <div v-if="type===2" class="fee">{{$t('Withdraw.fee')}}:{{ Withdrawobj.bank_fee }}</div>
  <div class="btn" @click="withdrawalinfo">{{$t('Withdraw.Submit')}}</div>
  <div class="hint"></div>
  </div>
  </van-pull-refresh>
  </div>
</template>

<script>
import {
  withdrawalapi,
  withdrawalinfoapi
} from '@/api/index'
import {
  Toast
} from 'vant'
export default {
  name: 'WithDraw',
  data () {
    return {
      Withdrawobj: {},
      money: 0,
      available: 0,
      type: 2,
      bank: '',
      codes: 1,
      isLoading: false,
      loding: 1, // 防爆
      bankmax: 0
    }
  },
  created () {
    this.withdrawal()
  },
  methods: {
    onRefresh () {
      setTimeout(() => {
        this.$router.go(0)
      }, 1000)
    },
    numValid (money){ // 输入的最大值
      // console.log(money, this.type)
      // console.log(parseFloat(this.Withdrawobj.max).toFixed(2))
      if (this.type === 1) {
        if (money > parseFloat(this.Withdrawobj.max)) {
          this.money = parseFloat(this.Withdrawobj.max).toFixed(2)
        }
      } else if (this.type === 2) {
        if (money > parseFloat(this.Withdrawobj.bank_max)) {
          this.money = parseFloat(this.Withdrawobj.bank_max).toFixed(2)
        }
      }
    },
    // 提现类型
    boxtype (index) {
      console.log(index)
      if (index === 1) {
        this.type = 1
        this.money = 0
      } else {
        this.type = 2
        this.money = 0
      }
    },
    // 提现信息
    async withdrawal () {
      const res = await withdrawalapi()
      console.log('666', res)
      this.Withdrawobj = res.data
      console.log(res.data.channel[0].type)
      this.type = res.data.channel[0].type
      // this.bankmax = parseFloat(res.data.max)
      this.available = parseFloat(res.data.available).toFixed(2)
      const div = document.querySelector('.hint')
      if (div) {
        div.innerHTML = res.data.tips
      }
      this.bank = res.data.bank_account
    },
    // 提现接口
    async withdrawalinfo () {
      console.log(this.Withdrawobj)
      // return false
      if (this.Withdrawobj.wallet_address || this.bank) {
        // 银行提现最小最大金额
        if (this.type === 2) {
          // console.log(this.Withdrawobj, this.money)
          if (parseFloat(this.money) >= parseFloat(this.Withdrawobj.bank_min)) {
            if (this.loding === 1) {
              this.loding = 0
              const data = {
                price: parseFloat(this.money).toFixed(2),
                type: this.type
              }
              // console.log(parseFloat(this.money))
              const res = await withdrawalinfoapi(data)
              // console.log(res)
              // return flase
              Toast({
                message: res.msg,
                duration: 1200,
                className: 'noticeWidth' // 所加样式名
              })
              this.codes = res.code
              this.withdrawal()
              if (!this.bank) {
                setTimeout(() => {
                  this.$router.push('/address')
                }, 1200)
              } else {
                if (res.code === 1) {
                  setTimeout(() => {
                    this.$router.push('/withdrawalsrecord')
                  }, 1500)
                } else if (res.code === 204) {
                  setTimeout(() => {
                    this.$router.push('/recharges')
                  }, 1500)
                } else if (res.code === 203) {
                  setTimeout(() => {
                    this.$router.push('/address')
                  }, 1500)
                } else if (res.code === 205) {
                  setTimeout(() => {
                    this.$router.push('/mining')
                  }, 1500)
                } else if (res.code === 206) {
                  setTimeout(() => {
                    this.$router.push('/install')
                  }, 1500)
                }
              }
              this.loding = 1
            }
          } else {
            Toast({
              message: this.$t('addwith.tsyi') + this.Withdrawobj.bank_min,
              duration: 1200,
              className: 'noticeWidth' // 所加样式名
            })
          }
        } else { // USD提现最小最大金额
          if (this.Withdrawobj.wallet_address) {
            if (parseFloat(this.money) > parseFloat(this.Withdrawobj.max)) {
              Toast({
                message: this.$t('addwith.tsyi') + this.Withdrawobj.max,
                duration: 1200,
                className: 'noticeWidth' // 所加样式名
              })
              return false
            }
            if (parseFloat(this.money) >= parseFloat(this.Withdrawobj.min)) {
              if (this.loding === 1) {
                this.loding = 0
                const data = {
                  price: parseFloat(this.money).toFixed(2),
                  type: this.type
                }
                // console.log(parseFloat(this.money))
                const res = await withdrawalinfoapi(data)
                // console.log(res.msg)
                Toast({
                  message: res.msg,
                  duration: 1200,
                  className: 'noticeWidth' // 所加样式名
                })
                this.codes = res.code
                this.withdrawal()
                if (!this.Withdrawobj.wallet_address) {
                  setTimeout(() => {
                    this.$router.push('/address')
                  }, 1200)
                } else {
                  if (res.code === 0) {
                    this.type = 2
                  } else {
                    if (res.code === 1) {
                      setTimeout(() => {
                        this.$router.push('/withdrawalsrecord')
                      }, 1500)
                    } else if (res.code === 204) {
                      setTimeout(() => {
                        this.$router.push('/recharges')
                      }, 1500)
                    } else if (res.code === 203) {
                      setTimeout(() => {
                        this.$router.push('/address')
                      }, 1500)
                    } else if (res.code === 205) {
                      setTimeout(() => {
                        this.$router.push('/mining')
                      }, 1500)
                    } else if (res.code === 206) {
                      setTimeout(() => {
                        this.$router.push('/install')
                      }, 1500)
                    }
                  }
                }
                this.loding = 1
              }
            } else {
              Toast({
                message: this.$t('addwith.tssan') + this.Withdrawobj.min,
                duration: 1200,
                className: 'noticeWidth' // 所加样式名
              })
            }
          } else {
            Toast({
              message: this.$t('Withdraw.receiving'),
              duration: 1200,
              className: 'noticeWidth' // 所加样式名
            })
            setTimeout(() => {
              this.$router.push('/address')
            }, 1200)
          }
        }
      } else {
        Toast({
          message: this.$t('Withdraw.receiving'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        setTimeout(() => {
          this.$router.push('/address')
        }, 1200)
      }
    },
    // 全部
    allmoney () {
      // if (this.Withdrawobj.available < 10000) {
      //   if (this.type === 1) {
      //     this.money = parseFloat(this.Withdrawobj.available).toFixed(2) | 0
      //   } else if (this.type === 2) {
      //     this.money = parseFloat(this.Withdrawobj.bank_max).toFixed(2) | 0
      //   }
      // } else {
      //   this.money = 10000
      // }
      console.log(this.type)
      if (this.type === 1) {
        if (parseFloat(this.Withdrawobj.available) >= parseFloat(this.Withdrawobj.max)) {
          this.money = parseFloat(this.Withdrawobj.max).toFixed(2)
        } else {
          this.money = parseFloat(this.Withdrawobj.available).toFixed(2)
        }
      }
      if (this.type === 2) {
        if (parseFloat(this.Withdrawobj.available) >= parseFloat(this.Withdrawobj.bank_max)) {
          this.money = parseFloat(this.Withdrawobj.bank_max).toFixed(2)
        } else {
          this.money = parseFloat(his.Withdrawobj.available).toFixed(2)
        }
      }
    },
    toWithdrawalsrecord () {
      this.$router.push('/withdrawalsrecord')
    },
    // 钱包
    toaddress () {
      if (!this.Withdrawobj.wallet_address) {
        setTimeout(() => {
          this.$router.push('/address')
        }, 1200)
      }
    },
    // 银行
    bankaddress () {
      if (!this.Withdrawobj.bank_account) {
        setTimeout(() => {
          this.$router.push('/address')
        }, 1200)
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .Withdraw {
    background: url('../assets/ampbk.png') no-repeat;
    background-size: cover;
    padding: 0rem 1.6rem;
    // padding-top: 2.5rem;
    width: 100%;
    min-height: 100vh;

    .Withdraw-title {
       padding-top: 2.5rem;
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.5rem;

      .title {
        color: #000;
        font-size: 1.8rem;
        font-weight: 700;
      }

      .img {
        width: 1.55rem;
        height: 1.75rem;
      }

      ::v-deep .van-icon-arrow-left:before {
        margin-top: 0.4rem;
      }
    }

    .Amount {
      display: flex;
      color: #333;
      justify-content: space-between;
      font-size: 1.6rem;

      .right {
        display: flex;

        .Available {
          font-size: 1.4rem;
          margin-right: 1.3rem;
        }
      }
    }

    .money,
    .addres {
      height: 5.5rem;
      border: 3px solid #f6f6f6;
      border-radius: 1rem;
      margin-top: 1.5rem;
      color: #333;
      font-size: 1.4rem;
      display: flex;
      align-items: center;
      padding-right: 1rem;
      input {
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 1rem;
        border: none;
        padding: 0rem 2.5rem;
      }

      .all {
        color: #15b4fb;
        margin-left: 0.8rem;
      }
    }

    .addres {
      padding-left: 2.5rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }

    .type {
      display: flex;
      color: #fff;
      margin-top: 2rem;

      .box,
      .boxs {
        width: 6rem;
        height: 3rem;
        border: 1px solid #f6f6f6;
        border-radius: .5rem;
        margin-right: 2rem;
        text-align: center;
        line-height: 3rem;
        color: #333;
      }

      .boxs {
        border: none;
        background-color: #15b4fb;
        color: #fff;
      }
    }

    .address {
      color: #333;
      margin: 1.5rem 0rem;
      font-size: 1.6rem;
    }

    .info {
      display: flex;
      justify-content: space-between;
      color: #333;
      margin-top: 2rem;
      font-size: 1.3rem;
    }

    .fee {
      color: #333;
      margin-top: 1.5rem;
      font-size: 1.3rem;
    }

    .btn {
      height: 4.4rem;
      background-color: rgba(21, 180, 251, 1);
      border-radius: 2.2rem;
      text-align: center;
      line-height: 4.4rem;
      font-size: 1.4rem;
      color: #fff;
      margin-top: 2rem;
      margin-bottom: 2.5rem;
    }

    .hint {
      ::v-deep p {
        color: rgba(255, 255, 255, 0.6);
        font-size: 1.3rem;
        margin-top: 1rem;
      }
    }
  }
</style>
